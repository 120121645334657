<template>
  <div class="iss-main theme-bg">
    <div class="repeating-content">
      <tips-bar text="您可以将疑似重复联系人合并或转为有效联系人。">
        <template #btnList>
          <div class="btn" @click="clickHandle">返回</div>
        </template>
      </tips-bar>
      <div class="operation">
        <div class="operation-table">
          <!--        列表一  value: 0, label: '手动合并'  v-if="searchVal === 0" -->
          <grid
            v-if="searchVal === 0"
            ref="gridRef"
            :columns="columns"
            :urlParams="urlParams"
            :code="$route.path"
            tableTitle="疑似重复联系人"
            formWidth="500px"
            :items="items"
            :url="url"
            :btn-operation="btnOperation"
            :defaultValue="{
              status: 1,
              abnormal: searchVal,
            }"
            :showHeader="true"
            allowSelection
            :scroll="{ y: gridHeight }"
            @fnGrid="handleFnGrid"
          >
            <template #avatar="{ record }">
              <div class="nameClass">
                <div class="surname" v-if="record.name?.length > 0">
                  {{ record.name?.charAt(0) }}
                </div>
                <!--                <div class="nameText" :title="record.name">{{ record.name }}</div>-->
              </div>
            </template>

            <template #infoList="{ record }">
              <span v-for="(i, index) in record.infoList" :key="i.id">
                {{ i.phone }}
                <span v-if="index !== record.infoList.length - 1">,</span>
              </span>
            </template>

            <!--            <template #importContactList="{ record }">-->
            <!--              &lt;!&ndash;           人数   &ndash;&gt;-->
            <!--              <div-->
            <!--                v-if="-->
            <!--                  record.importContactList !== null &&-->
            <!--                  record.importContactList.length > 0-->
            <!--                "-->
            <!--              >-->
            <!--                {{ record.importContactList.length }}人-->
            <!--              </div>-->
            <!--              <div v-else>0人</div>-->
            <!--            </template>-->
            <template #operation="{ record }">
              <operation :options="options" :record="record" />
            </template>
          </grid>

          <!--        列表二 value: 3, label: '按姓名重复合并' searchVal
           value: 4, label: '按电话重复合并'
           -->
          <grid
            v-else
            ref="gridRef"
            :columns="columns"
            :code="$route.path"
            tableTitle="疑似重复联系人"
            formWidth="500px"
            :items="items"
            :urlParams="urlParams"
            :url="url"
            :btn-operation="btnOperation"
            :showHeader="true"
            :defaultValue="{
              status: 1,
              abnormal: searchVal,
            }"
            :allowSelection="{
              hideAllSelect: true,
              type: 'checkbox',
            }"
            :row-selection="{ type: 'checkbox' }"
            :scroll="{ y: gridHeight }"
            @fnGrid="handleFnGrid"
          >
            <!--        <template #selectAll="{ total }"> 全部（{{ total }}）</template>-->

            <template #avatar="{ record }">
              <div class="nameClass">
                <div class="surname" v-if="record.name?.length > 0">
                  {{ record.name?.charAt(0) }}
                </div>
                <!--                <div class="nameText" :title="record.name">{{ record.name }}</div>-->
              </div>
            </template>

            <template #infoList="{ record }">
              <span v-for="(i, index) in record.infoList" :key="i.id">
                {{ i.phone }}
                <span v-if="index !== record.infoList.length - 1">,</span>
              </span>
            </template>
            <template #isRepeat>
              <a-tag color="orange"> 新增 </a-tag>
            </template>
            <!--        todo     -->
            <template #expandedRowRender="{ record }">
              <div class="item-table">
                <a-table
                  :columns="columnsT"
                  :data-source="record.importContactList"
                  :pagination="false"
                  :showHeader="false"
                  :bordered="false"
                >
                  <template #name="{ text }">
                    <div class="nameName">
                      <div class="surname" v-if="text?.length > 0">
                        {{ text?.charAt(0) }}
                      </div>
                      <div class="nameText" :title="text">{{ text }}</div>
                    </div>
                  </template>

                  <template #infoList="{ record }">
                    <span v-for="(i, index) in record.infoList" :key="i.id" class='infoList'>
                      {{ i.phone }}
                      <span v-if="index !== record.infoList.length - 1">,</span>
                    </span>
                  </template>
                  <template #operation="{ record }">
                    <operation :options="options" :record="record" />
                  </template>
                </a-table>
              </div>
            </template>

            <template #importContactList="{ record }">
              <!--           人数   -->
              <div
                class="ren"
                v-if="
                  record.importContactList !== null &&
                  record.importContactList.length > 0
                "
              >
                {{ record.importContactList.length }}人
              </div>
              <div v-else>0人</div>
            </template>

            <template #operation="{ record }">
              <operation :options="options" :record="record" />
            </template>
            <!--            <template #operation="{ record }">-->
            <!--              <operation :options="options" :record="record" />-->
            <!--            </template>-->
          </grid>
        </div>
        <div class="operation-pagination"></div>
      </div>
    </div>
  </div>
  <a-modal
    v-model:visible="visible"
    title="合并联系人"
    :width="700"
    @ok="handleOk('visible')"
  >
    <a-form ref="formRef" :model="formState" layout="vertical" :rules="rules">
      <a-form-item
        label="姓名（单选）"
        name="name"
        v-if="dataList.nameList.length"
      >
        <a-radio-group name="radioGroup" v-model:value="formState.name">
          <template v-for="i in dataList.nameList" :key="i">
            <a-radio :value="i" v-if="i">
              {{ i }}
            </a-radio>
          </template>
        </a-radio-group>
      </a-form-item>
      <a-form-item
        label="头像（单选）"
        name="headPortrait"
        v-if="dataList.avatarList.length"
      >
        <a-radio-group name="radioGroup" v-model:value="formState.headPortrait">
          {{ dataList.avatarList }}
          <template v-for="i in dataList.avatarList" :key="i">
            <a-radio :value="i" v-if="i">
              {{ i }}
            </a-radio>
          </template>
        </a-radio-group>
      </a-form-item>
      <a-form-item
        label="电话号码（可多选）"
        name="phone"
        v-if="dataList.phoneList.length"
      >
        <a-checkbox-group v-model:value="formState.phone">
          <template v-for="i in dataList.phoneList" :key="i">
            <a-checkbox :value="i" name="phone" v-if="i">
              {{ i }}
            </a-checkbox>
          </template>
        </a-checkbox-group>
      </a-form-item>
      <!--     name="company"  -->
      <a-form-item label="公司（单选）" v-if="dataList.companyList.length">
      <a-checkbox-group v-model:value="formState.company">
          <template v-for="i in dataList.companyList" :key="i">
            <a-checkbox :value="i" v-if="i">
              {{ i }}
            </a-checkbox>
          </template>
        </a-checkbox-group>

<!--        <a-radio-group name="radioGroup" v-model:value="formState.company">-->
<!--          <template v-for="i in dataList.companyList" :key="i">-->
<!--            <a-radio :value="i" v-if="i">-->
<!--              {{ i }}-->
<!--            </a-radio>-->
<!--          </template>-->
<!--        </a-radio-group>-->

        <!--       name="position" name="radioGroup"  -->
      </a-form-item>
      <a-form-item label="职位（单选）" v-if="dataList.positionList.length">
        <a-checkbox-group v-model:value="formState.position">
          <template v-for="i in dataList.positionList" :key="i">
            <a-checkbox :value="i" v-if="i">
              {{ i }}
            </a-checkbox>
          </template>
        </a-checkbox-group>
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal
    v-model:visible="phoneVisible"
    :closable="false"
    @ok="handleOk('phoneVisible')"
  >
    <div style="margin: 20px">
      <ExclamationCircleFilled class="l-text-primary mr-10 fs-20" />
      <span class="fs-16">
        合并后将保留1个姓名，其它姓名将写入后缀是否合并。
      </span>
    </div>
  </a-modal>
  <!-- todo  -->
  <a-modal
    v-model:visible="nameVisible"
    :closable="false"
    @ok="handleOk('nameVisible')"
  >
    <div style="margin: 20px">
      <ExclamationCircleFilled class="l-text-primary mr-10 fs-20" />
      <span class="fs-16">
        请确认以下名字重复联系人均属于同一个人，确认后名字重复的联系人号码将合并至一个联系人信息中。
      </span>
    </div>
  </a-modal>
  <!-- 转为有效联系人失败人员名单 -->
  <a-modal
    v-model:visible="errorVisible"
    okText="一键删除"
    @ok="deletePeople"
    :width="900"
    :cancel-button-props="{ style: { display: 'none' } }"
  >
    <div class="fs-16">重复联系人</div>
    <div class="mt-20">
      <ExclamationCircleFilled class="l-text-primary fs-20" />
      <span class="fs-18">
        此部分联系人电话号码在有效联系人中已存在，请勿重复添加。
      </span>
    </div>
    <div class="selectAll mt-20 mb-10">
      <a-checkbox @change="selectAllChange">全选</a-checkbox>
    </div>
    <a-table
      :columns="errorTable.columns"
      row-key="id"
      :data-source="errorTable.dataSource"
      :pagination="errorTable.pagination"
      :showHeader="false"
      :row-selection="{
        selectedRowKeys: errorTable.ids,
        onChange: onSelectChange,
      }"
    >
    </a-table>
  </a-modal>
  <edit-page-one
    v-model:visible="visibleEdit"
    :init="initValue"
    @fnOk="handleAddPage"
  />
</template>

<script>
import tipsBar from './components/tipsBar';
import { useRouter, useRoute } from 'vue-router';
import Grid from '@/components/connGrid';
import {
  reactive,
  toRefs,
  getCurrentInstance,
  ref,
  watch,
  onMounted,
} from 'vue';
import { Form, Checkbox, Radio, Table, message } from 'ant-design-vue';
import { ExclamationCircleFilled } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import dashboardApi from '@/api/dashboard.js';
import editPageOne from '@/views/dashboard/components/editPageOne';
import operation from '@/components/operation';
// import { Divider, Popconfirm, Space, Tooltip } from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';

export default {
  name: 'repeating',
  components: {
    ExclamationCircleFilled,
    tipsBar,
    Grid,
    ACheckbox: Checkbox,
    ACheckboxGroup: Checkbox.Group,
    ARadioGroup: Radio.Group,
    ARadio: Radio,
    AForm: Form,
    AFormItem: Form.Item,
    ATable: Table,
    editPageOne,
    operation,
  },
  setup() {
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const gridRef = ref();
    const formRef = ref();
    const state = reactive({
      ids: [],
      search: {},
      visibleEdit: false,
      initValue: {},
      visible: false,
      loading: false,
      nameVisible: false,
      phoneVisible: false,
      errorVisible: false,
      searchVal: '',
      dataList: {
        nameList: [],
        phoneList: [],
        companyList: [],
        avatarList: [],
        positionList: [],
      },
      formState: {
        phone: [],
        headPortrait: null,
        name: null,
        company: '',
        position: [],
      },
      meshldList: [],
      isRepeat: null,
    });
    const errorTable = reactive({
      ids: [],
      pagination: {
        showLessItems: true,
        showSizeChanger: true,
        showQuickJumper: true,
        size: 'default',
        pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
        showTotal: total => `共 ${total} 项数据`,
      },
      columns: [
        {
          dataIndex: 'avatar',
          title: '头像',
          width: 60,
          ellipsis: true,
          slots: { customRender: 'avatar' },
        },
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          width: 150,
          ellipsis: true,
        },
        {
          title: '公司',
          dataIndex: 'company',
          key: 'company',
          width: 150,
          ellipsis: true,
        },
        {
          title: '电话',
          dataIndex: 'phone',
          key: 'phone',
          ellipsis: true,
          width: 125,
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 60,
          slots: { customRender: 'operation' },
        },
      ],
      dataSource: [],
    });
    const rules = {
      name: [
        {
          required: true,
          message: '选择姓名',
          trigger: 'change',
        },
      ],
      headPortrait: [
        {
          required: true,
          message: '请选择头像',
          trigger: 'change',
        },
      ],
      phone: [
        {
          type: 'array',
          required: true,
          message: '请选择电话',
          trigger: 'change',
        },
      ],
      company: [
        {
          required: true,
          message: '请选择公司',
          trigger: 'change',
        },
      ],
      position: [
        {
          required: true,
          message: '请选择职位',
          trigger: 'change',
        },
      ],
    };
    const clickHandle = () => {
      router.back();
    };

    proxy.$bus.on('CONN-SEARCH', obj => {
      console.log('searchVal', obj)
      state.search = obj;

      if (obj.abnormal === 0) {
        state.searchVal = obj.abnormal;
      } else if (obj.abnormal === 3 || obj.abnormal === 4) {
        console.log(' obj.abnormal', obj.abnormal);
        state.searchVal = obj.abnormal;
      }
    });
    // 合并
    const handleOk = selType => {
      if (state.loading) return;
      state.loading = true;
      let type = '1';
      if (state.searchVal === 3) {
        type = '2';
      } else if (state.searchVal === 4) {
        type = '3';
      }
      if (selType === 'visible') {
        formRef.value.validateFields().then(() => {
          // 手动合并
          if (state.formState.company.length > 1) {
            state.loading = false;
            return message.error('只能选择一个公司合并');
          } else if (state.formState.position.length > 1) {
            state.loading = false;
            return message.error('只能选择一个职位合并');
          } else {
            dashboardApi
              .mergePeople({
                userId: store.state.account.user.id,
                importSituation: route.query.importStatus,
                type,
                importContactIdList: state.ids,
                importContactSaveDTO: {
                  name: state.formState.name,
                  avatar: state.formState.headPortrait,
                  company: state.formState.company[0],
                  phoneList: state.formState.phone,
                  position: state.formState.position[0],
                },
              })
              .then(res => {
                if (res) {
                  message.success('合并成功');
                  state.loading = false;
                  state[selType] = false;
                  // formState
                  gridRef.value.refreshGrid();
                }
              })
              .catch(() => {
                message.error('操作失败');
                state.loading = false;
              });
          }
        });
      } else {
        // 条件合并
        dashboardApi
          .mergePeople({
            userId: store.state.account.user.id,
            importSituation: route.query.importStatus,
            type,
            // meshIdList: state.meshIdList,
            selectAll: gridRef.value.selectAll().selectAll,
            importContactIdList: gridRef.value.selectAll().contactId,
            effective: 3,
            abnormal: state.searchVal,
            repeat: true,
            name: state.search.name,

          })
          .then(res => {
            if (res) {
              message.success('合并成功');
              state.loading = false;
              state[selType] = false;
              gridRef.value.refreshGrid(gridRef.value.selectAll().contactId.length);
            }
          })
          .catch(() => {
            message.error('操作失败');
            state.loading = false;
          });
      }
    };
    const selectAllChange = e => {
      errorTable.ids = e.target.checked
        ? errorTable.dataSource.map(i => i.id)
        : [];
    };
    const onSelectChange = ids => {
      errorTable.ids = ids;
    };
    const deletePeople = () => {
      if (state.loading || !errorTable.ids.length) return;
      state.loading = true;
      dashboardApi
        .removeByContactId({
          userId: store.state.account.user.id,
          ids: errorTable.ids,
        })
        .then(res => {
          if (res) {
            message.success('删除成功');
            state.loading = false;
            errorTable.ids = [];
            errorTable.dataSource = errorTable.dataSource.filter(
              i => !errorTable.ids.includes(i.id)
            );
            gridRef.value.refreshGrid();
          }
        })
        .catch(() => {
          message.error('操作失败');
          errorTable.ids = [];
          state.loading = false;
        });
    };
    watch(
      () => state.visible,
      val => {
        if (!val) {
          state.formState.company = null;
          state.formState.headPortrait = null;
          state.formState.name = null;
          state.formState.phone = [];
          formRef.value.clearValidate();
        }
      }
    );

    onMounted(() => {
      state.searchVal = 0;
    });

    const handleEdit = record => {
      state.visibleEdit = true;
      if (
        !record.infoList ||
        record.infoList.length === 0 ||
        !record.infoList[0].phone
      ) {
        record['infoList'] = [];
        record['infoList'][0] = {
          userId: record.userId,
          phone: '',
        };
      }
      Object.assign(state.initValue, JSON.parse(JSON.stringify(record)));
    };

    const handleDelete = record => {
      dashboardApi
        .removeByContactId({
          userId: store.state.account.user.id,
          ids: [record.id],
        })
        .then(res => {
          if (res) {
            message.success('删除成功');
            state.loading = false;
            gridRef.value.refreshGrid([record.id].length);
          }
        })
        .catch(() => {
          message.error('操作失败');
          state.loading = false;
        });
    };

    // 设置用户导入行为页面信息 /dashboard  设置9
    // dashboardApi
    //     .setBehaviorPage({
    //       userId: store.state.account.user.id,
    //       importSituation: route.query.importStatus,
    //       pageName: '/dashboard/repeating',
    //     })
    //     .then(res => {
    //       if (res) {
    //         console.log('res', res);
    //       }
    //     });

    return {
      handleOk,
      deletePeople,
      selectAllChange,
      onSelectChange,
      clickHandle,
      formRef,
      rules,
      icons,
      ...toRefs(state),
      gridRef,
      gridHeight: document.body.clientHeight - 280,
      url: '/connections_treasure_box/contact/import-contact/page',
      urlParams: {
        userId: store.state.account.user.id,
        importSituation: route.query.importStatus,
        effective: 3,
        // abnormal: 0,
      },
      errorTable,
      handleAddPage(value) {
        state.visibleEdit = value;
        gridRef.value.refreshGrid();
      },
      items: [
        {
          key: 'abnormal',
          label: '类型',
          // search: false,
          type: 'select',
          dataset: [
            { value: 0, label: '手动合并' },
            { value: 3, label: '按姓名重复合并' },
            { value: 4, label: '按电话重复合并' },
          ],
        },
        { key: 'name', label: '搜索姓名、公司、职位' },
      ],
      // 按名字和电话重复
      btnOperation: [
        {
          type: 'selectAll',
          label: '跨页全选',
          permission: 'resources:selectAll',
          fnClick: () => {
            // console.log('全选', gridRef.value.getSelectedRowsKeys());
          },
        },
        {
          type: 'delete',
          label: '删除',
          icon: '',
          btnType: 'primary',
          permission: 'clue:delete',
          fnClick: ids => {
            // todo 重复
            if (ids.length) {
              if (state.search.abnormal === 3 || state.search.abnormal === 4) {
                state.isRepeat = true
              } else {
                state.isRepeat = false
              }

              dashboardApi
                .removeByContactId({
                  userId: store.state.account.user.id,
                  importSituation: route.query.importStatus,
                  effective: 3,
                  selectAll: gridRef.value.selectAll().selectAll,
                  ids: gridRef.value.selectAll().contactId,
                  abnormal: state.search.abnormal,
                  name: state.search.name,
                  repeat: state.isRepeat,
                })
                .then(res => {
                  if (res) {
                    message.success('删除成功');
                    state.loading = false;
                    // 删除的如何计算边界值
                    gridRef.value.refreshGrid(gridRef.value.selectAll().contactId.length);
                  }
                })
                .catch(() => {
                  message.error('操作失败');
                  state.loading = false;
                });
            }
          },
        },
        {
          label: '合并',
          btnType: 'default',
          permission: 'merge',
          fnClick: (ids, data) => {
            // if (ids.length > 1) {ids)

            state.ids = ids;
            const arr = data.filter(i => {
              return ids.includes(i.id);
            });

            if (state.searchVal === 0) {
              const arr = data.filter(i => {
                return ids.includes(i.id);
              });

              if (gridRef.value.selectAll().selectAll) {
                return message.error('手动合并不支持跨页全选')
              }

              if (arr.length > 10)
                return message.info('手动合并不可选择超过十条数据');
              state.dataList.nameList = Array.from(
                new Set(arr.map(i => i.name))
              ).filter(i => i && i.trim());
              state.dataList.companyList = Array.from(
                new Set(arr.map(i => i.company))
              ).filter(i => i && i.trim());

              state.dataList.avatarList = Array.from(
                new Set(arr.map(i => i.avatar))
              ).filter(i => i && i.trim());
              state.dataList.positionList = Array.from(
                new Set(arr.map(i => i.position))
              ).filter(i => i && i.trim());

              state.dataList.phoneList = Array.from(
                new Set(
                  arr.map(i => i.infoList.map(item => item.phone).join(','))
                )
              ).filter(i => i && i.trim());

              state.visible = true;

              // 姓名,电话,公司,职业可能只有一个时直接默认选择
              if (state.dataList.positionList.length === 1) {
                state.formState.position = state.dataList.positionList;
              }
              if (state.dataList.companyList.length === 1) {
                state.formState.company = state.dataList.companyList;
              }
              if (state.dataList.nameList.length === 1) {
                state.formState.name = state.dataList.nameList[0];
              }
              if (state.dataList.phoneList.length === 1) {
                state.formState.phone = state.dataList.phoneList;
              }
            } else if (state.searchVal === 3) {

              const meshIdList = arr.map(i => {
                return {
                  importContactId: i.id,
                  importContactIdList:
                    i.importContactList?.map(ci => ci.id) || [],
                };
              });
              console.log('meshIdList22', meshIdList)
              state.meshIdList = meshIdList;

              // 按名字重复
              state.nameVisible = true;
            } else if (state.searchVal === 4) {
              const meshIdList = arr.map(i => {
                return {
                  importContactId: i.id,
                  importContactIdList:
                    i.importContactList?.map(ci => ci.id) || [],
                };
              });

              console.log('meshIdList33', meshIdList)
              state.meshIdList = meshIdList;
              // 按手机号码重复
              state.phoneVisible = true;
            }

            // } else {
            //   message.info('请选择两条以上的数据进行合并');
            // }
          },
        },
        {
          label: '转为有效',
          btnType: 'default',
          permission: 'change',
          fnClick: (ids, data) => {
            if (gridRef.value.selectAll().selectAll) {
              return message.error('转为有效功能不支持跨页全选')
            }
            if (ids.length && data.length > 0) {
              const arr = data.filter(i => {
                return ids.includes(i.id);
              });
              const canChange = arr.some(i => {
                return (
                  i.name?.length == 0 ||
                  !i.infoList ||
                  i.infoList?.length == 0 ||
                  i.infoList[0].phone.length === 0
                );
              });
              if (canChange) {
                message.error('转为有效联系人，姓名和手机号不能为空');
              } else {
                if (state.loading) return;
                state.loading = true;
                const obj = {
                  userId: store.state.account.user.id,
                  importSituation: route.query.importStatus,
                  // importContactIdList: [...ids],
                  importContactIdList: gridRef.value.selectAll().contactId,
                  effective: 3,
                  abnormal: state.searchVal,
                  selectAll: gridRef.value.selectAll().selectAll,

                };
                dashboardApi
                  .putEffect1(obj)
                  .then(res => {
                    if (res) {
                      dashboardApi
                        .putEffect2(obj)
                        .then(res => {
                          if (res.length == 0) {
                            dashboardApi
                              .putEffect3({
                                ...obj,
                                type: 4,
                              })
                              .then(res => {
                                if (res) {
                                  message.success('转换成功');
                                  gridRef.value.refreshGrid(ids.length);
                                  state.loading = false;
                                }
                              })
                              .catch(() => {
                                message.error('操作失败');
                                state.loading = false;
                              });
                          } else {
                            state.loading = false;
                            state.errorVisible = true;
                            errorTable.dataSource = res;
                          }
                        })
                        .catch(() => {
                          message.error('操作失败');
                          state.loading = false;
                        });
                    } else {
                      state.loading = false;
                      message.error('存在重复联系人');
                    }
                  })
                  .catch(() => {
                    message.error('操作失败');
                    state.loading = false;
                  });
              }
            }
          },
        },
      ],
      columns: [
        {
          dataIndex: 'avatar',
          title: '头像',
          width: 60,
          ellipsis: true,
          slots: { customRender: 'avatar' },
        },
        {
          title: '姓名',
          dataIndex: 'name',
          width: 150,
          slots: { customRender: 'name' },
          ellipsis: true,
        },
        {
          title: '公司',
          dataIndex: 'company',
          width: 150,
          ellipsis: true,
        },
        {
          title: '职位',
          dataIndex: 'position',
          width: 150,
          ellipsis: true,
        },
        {
          title: '电话',
          dataIndex: 'infoList',
          slots: { customRender: 'infoList' },
          ellipsis: true,
          width: 120,
        },
        {
          dataIndex: 'importContactList',
          slots: { customRender: 'importContactList' },
          width: 80,
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'id',
          fixed: 'right',
          width: 90,
          slots: { customRender: 'operation' },
        },
      ],
      columnsT: [
        {
          dataIndex: 'avatar',
          title: '头像',
          width: 60,
          ellipsis: true,
          slots: { customRender: 'avatar' },
        },
        {
          title: '姓名',
          dataIndex: 'name',
          width: 150,
          slots: { customRender: 'name' },
          ellipsis: true,
        },
        {
          title: '公司',
          dataIndex: 'company',
          width: 150,
          ellipsis: true,
        },
        {
          title: '职位',
          dataIndex: 'position',
          width: 140,
          ellipsis: true,
        },
        {
          title: '电话',
          dataIndex: 'infoList',
          slots: { customRender: 'infoList' },
          ellipsis: true,
          width: 120,
        },
        {
          dataIndex: 'importContactList',
          slots: { customRender: 'importContactList' },
          width: 80,
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'id',
          fixed: 'right',
          width: 90,
          slots: { customRender: 'operation' },
        },
      ],
      options: [
        {
          type: 'delete',
          permission: 'form:delete',
          fnClick: record => {
            console.log('delete', record);
            handleDelete(record);

            // dashboardApi
            //     .deletePeople({
            //       userId: store.state.account.user.id,
            //       ids: [record.id],
            //     })
            //     .then(res => {
            //       if (res) {
            //         message.success('删除成功');
            //         state.loading = false;
            //         gridRef.value.refreshGrid();
            //       }
            //     })
            //     .catch(() => {
            //       message.error('操作失败');
            //       state.loading = false;
            //     });
          },
        },
        {
          type: 'update',
          label: '编辑',
          permission: 'invalid:update',
          fnClick: record => {
            // console.log('record', record);
            handleEdit(record);

            // state.visibleEdit = true;
            // if (
            //     !record.infoList ||
            //     record.infoList.length === 0 ||
            //     !record.infoList[0].phone
            // ) {
            //   record['infoList'] = [];
            //   record['infoList'][0] = {
            //     userId: record.userId,
            //     phone: '',
            //   };
            // }
            // Object.assign(state.initValue, JSON.parse(JSON.stringify(record)));
          },
        },
      ],
      handleFnGrid(val) {
        console.log('33534', val);
        // if (val.value) {
        //
        //     state.searchVal = val.value
        //   console.log('33534666', val, state.searchVal)
        // }
      },

      // 嵌套编辑和删除
      handleClickEdit(i) {
        handleEdit(i);
      },
      handleClick(i) {
        handleDelete(i);
      },
    };
  },
};
</script>

<style lang="less" scoped>
.theme-bg {
  background: linear-gradient(245deg, #ffedde 1%, #fff8f1 100%);
}
.repeating-content {
  width: 90%;
  margin: 0 auto;
  padding-top: 40px;
}
.btn {
  width: 100px;
  height: 42px;
  background: linear-gradient(270deg, #ffb64b 0%, #ff7b00 100%);
  border-radius: 8px;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  line-height: 42px;
  cursor: pointer;
}
.operation {
  margin: 20px 0;
  padding: 20px;
  background-color: #fff;
  .operation-header {
    .header-text {
      margin-bottom: 10px;
      font-size: 16px;
    }
  }
}
.surname {
  width: 36px;
  height: 36px;
  display: inline-block;
  background-color: @primary-color;
  border-radius: 50%;
  font-size: 16px;
  text-align: center;
  //line-height: 36px;
  color: #fff;
  //margin-right: 8px;
  min-width: 36px;
  padding-top: 4px;
}
:deep(.ant-checkbox-wrapper) {
  margin-left: 0px;
  margin-right: 8px;
}
:deep(.repeating-content .ant-checkbox-wrapper) {
  margin-left: 0px;
  margin-right: 0px;
}
.item-table {
  padding-left: 60px;
  :deep(.ant-table-tbody > tr > td) {
    border-bottom: none;
  }
  :deep(.ant-table-scroll) {
    overflow: hidden;
  }
}
:deep(.ant-table-expanded-row > td:nth-child(2)) {
  padding: 0px !important;
}
.expanded-name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // .surname {
  // }
}
.actionButtons {
  width: 158px;
  text-align: right;
}
.noneBorder {
  border-bottom: none;
  padding-bottom: 0px;
}
.iss-btn {
  color: @primary-color;
  border: none;
  //background: #fff;
  background: rgba(255, 123, 0, 0.08);
}

// .operation-table/deep/
//   .ant-table.ant-table-middle
//   .ant-table-content
//   > .ant-table-scroll
//   > .ant-table-body
//   > table
//   > .ant-table-tbody
//   > tr
//   > td {
//   padding: 7px px 7px 16px !important;
// }

.operation-table /deep/ tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover {
  background: #ffffff;
}
:deep(.ant-table-row-expand-icon-cell) {
  padding-left: 21px !important;
}
.operation-table /deep/ .ant-table-row-cell-ellipsis,
.ant-table-row-cell-ellipsis .ant-table-column-title {
  white-space: break-spaces;
  text-overflow: ellipsis;
}
.nameName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .surname {
    margin-right: 8px;
  }
  .nameText {
    flex: 1;
  }
}
.ren {
  text-align: right;
}
.infoList {
  margin-left: 8px;
}
</style>
